export const DS_DASHBOARD = {
    name: "DS Dashboard",
    route: "/admin/ds-dashboard",
    permissions: ["CLIENT_VIEW"],
    extraRoutes: [
        {
            route: "/admin/details/:id",
            permissions: ["CLIENT_VIEW"],
            roles: [],
        }
    ]
}