import { Collapse, Tab, Tabs, styled } from "@mui/material";
import React from "react";
import SubMenuArrow from "../../assets/svg-icons/arrow-right.svg";
import { useTabNavigation } from "../../hooks/useTabNavigation";
import { APPBAR_MENU, ENABLE_FOR_MENU } from "../../utils/const";
import CollapseDropdown from "./collapse-dropdown";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    transition: "all 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .MuiTabs-indicator:after": {
    content: '""',
    display: "block",
    width: "3rem",
    height: "1.3rem",
    borderRadius: "0.3rem",
    backgroundColor: theme.palette.brandColor.main,
  },
  "& .MuiTabs-scroller": {
    overflowX: "unset",
  },
  "& .MuiTabs-scrollButtons": {
    background: "#2B2B2B",
    opacity: 1,
    position: "absolute",
    right: "-0.2rem",
    top: "-0.4rem",
    borderRadius: "50%",
    width: "2.3rem",
    height: "2.3rem",
    padding: "1rem 0.1rem",
    boxShadow: "rgba(0, 0, 0, 0.24) 0rem 0.3rem 0.8rem",
    "& .MuiSvgIcon-root": {
      color: "#898A93",
    }
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: "auto",
}));

const TabNavigation = ({
  content,
  classname,
  isSuperMenu = false,
  setShowSubMenu,
  setSelectedMenu,
  selectedMenu,
  clickedMenu,
}) => {
  const {
    activeTab,
    expanded,
    animate,
    isSubMenu,
    isScrollable,
    isEmptySNOP,
    subMenu,
    handleChange,
    handleMenuClose,
    handleMenuExpansion,
    handleDropDownMenuClick,
  } = useTabNavigation({
    content,
    classname,
    isSuperMenu,
    setShowSubMenu,
    setSelectedMenu,
    selectedMenu,
    clickedMenu,
  });

  return (
    <>
      <CustomTabs
        value={activeTab}
        //onChange={handleChange}
        variant={!!isScrollable ? "scrollable" : "standard"}
        scrollButtons={!!isScrollable}
        centered
        TabIndicatorProps={{
          style: isSuperMenu ? { display: "none" } : { transition: "none" },
        }}
        className={`${classname ?? "default-tab"} ${animate ? "animate-border-radius" : ""
          } ${isSubMenu ? "scrollable-tabs" : ""} ${isSuperMenu && "height-super"
          }`}
      >
        {content?.map((menu_item, idx) => {
          const isTabDisabled =
            !ENABLE_FOR_MENU.includes(menu_item?.name) &&
            isEmptySNOP &&
            !classname &&
            selectedMenu?.parent === APPBAR_MENU?.IBP;

          return (
            <CustomTab
              key={`${menu_item?.name}_${idx}`}
              className={`tabs ${isTabDisabled && "disabled-tab"} ${isSuperMenu && "padding-super"
                }`}
              sx={{
                "&.Mui-selected": isSuperMenu
                  ? {
                    color: "white!important",
                    backgroundColor: "#545454",
                  }
                  : {
                    color: "white!important",
                  },
                opacity: isTabDisabled ? 0.4 : 1,
              }}
              disableRipple
              label={menu_item?.name}
              icon={
                isSubMenu && menu_item?.child ? (
                  <img src={SubMenuArrow} alt="SubMenuArrow" />
                ) : null
              }
              iconPosition="end"
              onClick={(e) => handleChange(e, idx)}
            />
          );
        })}
      </CustomTabs>

      {subMenu?.length > 0 && (
        <Collapse in={expanded} unmountOnExit timeout={300}>
          <CollapseDropdown
            expanded={expanded}
            onMenuClose={handleMenuClose}
            subMenuList={subMenu}
            handleDropDownMenuClick={handleDropDownMenuClick}
          />
        </Collapse>
      )}
    </>
  );
};

export default TabNavigation;
