// SCAILayout.js (Main Layout Component)
import React from 'react';
import { Box, Stack, ThemeProvider } from "@mui/material";
import { useRef } from "react";
import { useSelectedMenu } from "../../hooks/useSelectedMenu";
import "../../style/notificationCommonComp.scss";
import { customTheme } from "../../utils/theme";
// import "./style.scss";
import { useScrollContainerClass } from "../../hooks/useScrollContainerClass";
import NavigationMenu from "../NavigationMenu";
import "./styles.scss";

const SCAILayout = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const { selectedMenu, setSelectedMenu } = useSelectedMenu();
  const containerClass = useScrollContainerClass(scrollContainerRef);

  return (
    <>
      <Box className="main-container">
        <ThemeProvider theme={customTheme}>
          <Box
            component="main"
            className="layout-content-main"
            ref={scrollContainerRef}
          >
            <Stack className={`navigation-container`}>
              <NavigationMenu
                selectedMenu={selectedMenu}
                containerClass={containerClass}
                setSelectedMenu={setSelectedMenu}
              />

              {/* Child content area */}
              <Box className="child-container">{children}</Box>
            </Stack>
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default SCAILayout;
