// eslint-disable-next-line import/no-anonymous-default-export

/* 
List of All the literals and constants used in Project
*/
const constKeys = {
  api: "",
  API_METHOD_POST: "POST",
  API_METHOD_GET: "GET",
  API_METHOD_PUT: "PUT",
  API_METHOD_PATCH: "PATCH",
  API_METHOD_DELETE: "DELETE",
  API_METHOD_HEAD: "HEAD",
  FORM_VALIDATION_EXAMPLE: "Form Inputs Validation Example",
  ERROR_BOUNDARY_EXAMPLE: "Error Boundary Example",
  API_RESPONSE_ERROR: "Something went wrong with API",
  GLOBAL_ERROR: "Something went wrong",
};

export const supportedLanguage = ["en-US"];

export const adminType = {
  "3SCAdmin": "3SCAdmin",
  "Client Admin": "Client Admin",
};

export const createThemeSelect = (theme) => {
  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export const userEditInitialValues = {
  organization: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile_country_code: "",
  mobile: "",
  team: "",
  business_unit: "",
  address_line1: "",
  address_line2: "",
  country: null,
  state: null,
  city: null,
  pincode: "",
  roles: "",
};

export const phoneInputValidateMsg = "This field is mandatory";

export const nameValidation = [
  "required",
  "alpha_num_dash_space",
  { max: "64" },
];

export default constKeys;

export const colorPalette = {
  grey: "#72757a",
  green: "#1BD365",
};
