import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React from "react";

const CollapseDropdown = ({
  expanded,
  onMenuClose,
  subMenuList,
  handleDropDownMenuClick,
}) => {
  return (
    <>
      {/* <Backdrop open={expanded} sx={{
                zIndex: -3
            }} /> */}
      <Box
        component="div"
        sx={{
          backgroundColor: "#2c2c2c",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderBottomLeftRadius: "2rem",
          borderBottomRightRadius: "2rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "1rem 2.7rem",
        }}
      >
        <Box>
          <List>
            {subMenuList?.map((item, index) => (
              <>
                <ListItem key={`${item}_${index}`}>
                  <ListItemButton
                    sx={{
                      fontSize: "1.2rem",
                      fontFamily: `'Euclid Circular Regular', sans-serif`,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      color: "brandColor.grey3",
                    }}
                    onClick={() => handleDropDownMenuClick(item)}
                  >
                    {item?.name}
                  </ListItemButton>
                </ListItem>
                {index !== subMenuList.length - 1 && (
                  <Divider
                    key={`divider_${item}_${index}`}
                    variant="middle"
                    component="li"
                    sx={{
                      borderColor: "#545454",
                    }}
                  />
                )}
              </>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <Typography
            component="span"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "brandColor.main",
              fontSize: "1.2rem",
              fontFamily: `'Euclid Circular Regular', sans-serif`,
            }}
            onClick={onMenuClose}
          >
            Close
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CollapseDropdown;
