// components/NavigationMenu.js
import { Notification } from "@3sc/common-component";
import { Box, Stack } from "@mui/material";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/svg-icons/3SC-logo.svg";
import Logout from "../../assets/svg-icons/Logout.svg";
import { isNotificationEnabled } from "../../helper/commonHelper";
import useMenuData from "../../hooks/useMenuData";
import "../../style/notificationCommonComp.scss";
import { alertApiEndpoint, logout } from "../../utils/ApiHandler";
import { APPBAR_MENU } from "../../utils/const";
import { ROUTES_PATH } from "../../utils/EndPoints";
import MultiAvatar from "../MultiAvatar";
import TabNavigation from "../TabNavigation";
// import "./style.scss";

const NavigationMenu = ({ setSelectedMenu, selectedMenu, containerClass }) => {
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const menuDatas = useMenuData(selectedMenu);
  const user = JSON.parse(Cookies.get("user") || null);
  const logo = user?.logo ?? null;
  const handleLogout = () => {
    logout();
    navigate(ROUTES_PATH.ROUTE);
  };

  return (
    <>
      {/* Top Navigation menu */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="top-nav-section"
      >
        {/* User logo */}
        <Box className="logo-section">
          <img src={logo ?? Logo} alt="Logo" />
        </Box>

        {/* Product main-menu navigation */}
        <TabNavigation
          content={menuDatas?.parent}
          classname={"tabs-container"}
          isSuperMenu
          setSelectedMenu={setSelectedMenu}
          selectedMenu={selectedMenu}
          clickedMenu={selectedMenu?.parent}
        />

        {/* Logout section */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="logout-section"
        >
          <MultiAvatar selectedMenu={selectedMenu} />
          <img src={Logout} alt="Logout"
            onClick={handleLogout}
            className={`${selectedMenu?.parent !== APPBAR_MENU.ID ? "logout-button" : ""
              }`}
          />
        </Stack>
      </Stack>

      {/* Sticky Header */}
      <Box
        className={`${containerClass}`}
        style={{ position: "sticky", top: 0, zIndex: 100 }}
      >
        <Stack
          direction={"row-reverse"}
          justifyContent={"space-between"}
          className={`bottom-nav-section ${!showSubMenu ? "collapsed-bottom-nav" : ""
            }`}
        >
          {/* Main menu navigation */}
          {Object.keys(menuDatas?.menu)?.length > 0 && (
            <TabNavigation
              content={menuDatas?.menu}
              setShowSubMenu={setShowSubMenu}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              clickedMenu={selectedMenu?.menu}
            />
          )}

          {/* Notification button (3sc common component) */}
          {isNotificationEnabled() && (
            <Notification baseUrl={alertApiEndpoint} />
          )}
        </Stack>

        {/* Sub-Menu Navigation */}
        <Box className={`sub-menu-navigation ${showSubMenu ? "show" : ""}`}>
          {Object.keys(menuDatas?.subMenu || {}).length > 0 && (
            <TabNavigation
              content={Object.values(menuDatas?.subMenu)}
              classname={"tabs-container"}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              clickedMenu={selectedMenu?.subMenu}
            />
          )}
        </Box>

        <Box
          className={`extra-without-space ${showSubMenu ? "extra-space" : ""}`}
        />
      </Box>
    </>
  );
};

export default NavigationMenu;
