import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../utils/ApiHandler";
// import "./forgot-password.scss";
import { Box, InputBase, Stack, Typography } from "@mui/material";
import ProcessingLoader from "../../components/ProcessingLoader";
import BackBtn from "../../components/button/back.btn";
import RipperContainer from "../../components/ripple-container";
import TypingAnimation from "../../components/typing-animation";
import ThreeDot from "../../components/typing-animation/three.dot";
import images from "../../constant/images";
import "../Login/Login.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, SetFormData] = useState({
    email: "",
  });
  const { t } = useTranslation();

  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailChange, seIsEmailChange] = useState(false);
  let emailChangeTimeout;

  const { email } = formData;

  // useEffect(() => {
  //   if (email && !validator.isEmail(email)) setEmailError(true);
  //   else setEmailError(false);
  // }, [email]);

  useEffect(() => {
    clearTimeout(emailChangeTimeout);
    if (isEmailChange) {
      emailChangeTimeout = setTimeout(() => {
        seIsEmailChange(false);
      }, 1000);
    }
    return () => {
      clearTimeout(emailChangeTimeout);
    };
  }, [email]);

  const onChange = (e) => {
    seIsEmailChange(true);
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value?.toLowerCase(),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await forgotPassword(email);
    if (res?.status === 200) {
      toast.success("Password reset link sent to your mail ID !!!");
      navigate(`/`);
    }
    setLoading(false);
  };

  if (loading) return <ProcessingLoader />;

  return (
    <>
      <Box className="login-container">
        <Box
          component="header"
          sx={{
            py: 2,
            px: 12,
            height: 64,
          }}
        >
          <Typography className="logo-heading">
            <TypingAnimation text="SCAi" />
          </Typography>
        </Box>
        <Box component="form" className="login-form" onSubmit={onSubmit}>
          <Stack className="email-con">
            <Typography variant="h1" className="heading">
              Recover your <br /> account?
            </Typography>

            <Typography className="paragraph">
              No worries, we'll send you reset link.
            </Typography>

            <Stack>
              <Box className="input-box">
                <InputBase
                  className="input-base"
                  placeholder="Enter e-mail address"
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Box className="indicator-box">
                  <ThreeDot isAnimation={isEmailChange} />
                </Box>
              </Box>
              <Box
                component="button"
                className="btn"
                disabled={emailError || !email || loading}
                type="submit"
              >
                Reset
              </Box>
              <Box
                sx={{
                  mt: "4rem",
                }}
              >
                <Link to={"/"}>
                  <BackBtn />
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
        <Box className="com-logo">
          <RipperContainer image={images.comLogo} />
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
