export const COM_MSG = {
  USER_UPDATED: "User updated successfully.",
};

export const ERROR_MSG = {
  SWR: "Something went wrong.",
  NO_DATA: "No data found.",
  LABEL: "Label not found.",
  MISSING_COLUMNS: "No missing columns",
};
