import { createTheme } from "@mui/material";

export const customTheme = createTheme({
    palette: {
        primary: {
            main: "#f5f7fa",
        },
        brandColor: {
            main: "#FF6D33",
            grey3: "#898A93"
        }
    },
    typography: {
        fontFamily: "Euclid Circular Regular, sans-serif",
        fontSize: 12,
        fontWeightMedium: "bold",
        fontWeightRegular: "lighter",
    },
    components: {
        MuiDrawer: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
});