import { createBrowserHistory } from "history";
import React from "react";
import MicroFrontend from "../../../MicroFrontend";
import SCAILayout from "../../../components/SCAILayout";

const defaultHistory = createBrowserHistory();

const EdmFrontend = () => {
  const { REACT_APP_EDM_HOST: edmHost } = process.env;

  function EdmModule({ history }) {
    return <MicroFrontend history={history} host={edmHost} name="edm" />;
  }

  return (
    <SCAILayout>
      <div id="edm">
        <div>
          <EdmModule history={defaultHistory} />
        </div>
      </div>
    </SCAILayout>
  );
};

export default EdmFrontend;
