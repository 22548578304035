export const PAI = {
  name: "Product Planning",
  route: null,
  permissions: [],
  child: {
    Forecasting: {
      name: "Forecasting",
      route: "/pp/forecast",
      permissions: ["MARKETING_FORECAST_VIEW"],
    },
  },
};
