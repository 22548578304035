import { createBrowserHistory } from "history";
import React from "react";
import SCAILayout from "../../../components/SCAILayout";
import MicroFrontend from "../../../MicroFrontend";

const defaultHistory = createBrowserHistory();

const SnpFrontend = () => {
  const { REACT_APP_SNP_HOST: snpHost } = process.env;

  function SnpModule({ history }) {
    return <MicroFrontend history={history} host={snpHost} name="snp" />;
  }

  return (
    <SCAILayout>
      <div id="snp">
        <div>
          <SnpModule history={defaultHistory} />
        </div>
      </div>
    </SCAILayout>
  );
};

export default SnpFrontend;
