import React from "react";
import { Route, Routes } from "react-router-dom";

// Layouts and Components
import ErrorPage from "../components/ErrorPage/Error";
import SCAILayout from "../components/SCAILayout";
import { ROUTES_PATH } from "../utils/EndPoints";
import PrivateRoute from "./PrivateRoute";

// Pages
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import MyProfile from "../pages/MyProfile";
import OTP from "../pages/OTP";

// Frontends
import AdminFrontend from "../pages/SCAIFrontends/AdminFrontend/AdminFrontend";
import DpaiFrontend from "../pages/SCAIFrontends/DpaiFrontend/DpaiFrontend";
import DSFrontend from "../pages/SCAIFrontends/DSFrontend";
import DTFrontend from "../pages/SCAIFrontends/DTFrontend";
import EdmFrontend from "../pages/SCAIFrontends/EdmFrontend/EdmFrontend";
import ProductFrontend from "../pages/SCAIFrontends/ProductFrontend";
import RMFrontend from "../pages/SCAIFrontends/RMFrontend";
import SnopFrontend from "../pages/SCAIFrontends/snopFrontend";
import SnpFrontend from "../pages/SCAIFrontends/SnpFrontend/SnpFrontend";

// Routes Constants
import { processNodeAndPermissions } from "../helper/com-helper";
import { RISKAI } from "../utils/RouteHelper/DTRM";
import { DT } from "../utils/RouteHelper/DTRM/DT";
import { EDM } from "../utils/RouteHelper/EDM";
import { DPAI } from "../utils/RouteHelper/IBP/DPAI";
import { PAI } from "../utils/RouteHelper/IBP/PAI";
import { SNOP } from "../utils/RouteHelper/IBP/SNOP";
import { SNP } from "../utils/RouteHelper/IBP/SNP";
import { ID } from "../utils/RouteHelper/InternalDashboard";
import { DS_DASHBOARD } from "../utils/RouteHelper/InternalDashboard/DS_DASHBOARD";

//Routing component
const Routing = () => {
  const mapRoutes = (container, containerName, component) => {
    let { routes } = processNodeAndPermissions(container);
    if (containerName === "RM") {
      const DTRoutes = processNodeAndPermissions(DT)?.routes;
      routes = routes.filter((path) => !DTRoutes.includes(path));
    }
    return routes.map((routeElement) => (
      <Route
        key={routeElement}
        path={routeElement}
        element={<PrivateRoute path={routeElement}>{component}</PrivateRoute>}
      />
    ));
  };

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/scai" element={<Login />} />
      <Route path="/login/callback" element={<Login />} />
      <Route path="/scai/forgot-password" element={<ForgotPassword />} />
      <Route path="/scai/login-mfa" element={<OTP />} />
      <Route
        path={ROUTES_PATH.MY_PROFILE}
        element={
          <SCAILayout>
            <MyProfile />
          </SCAILayout>
        }
      />

      {/* Dynamic routes */}
      {mapRoutes(DS_DASHBOARD, "DS_DASHBOARD", <DSFrontend />)}
      {mapRoutes(ID, "ID", <AdminFrontend />)}
      {mapRoutes(EDM, "EDM", <EdmFrontend />)}
      {mapRoutes(SNOP, "SNOP", <SnopFrontend />)}
      {mapRoutes(DPAI, "DPAI", <DpaiFrontend />)}
      {mapRoutes(SNP, "SNP", <SnpFrontend />)}
      {mapRoutes(RISKAI, "RM", <RMFrontend />)}
      {mapRoutes(DT, "DT", <DTFrontend />)}
      {mapRoutes(PAI, "PAI", <ProductFrontend />)}

      {/* Wildcard route */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Routing;
