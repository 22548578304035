import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProcessingLoader from "../components/ProcessingLoader";
import {
  handleAuthError,
  refreshAccessToken,
  verifyToken,
} from "../helper/authService";
import { checkRoutePermissions } from "../helper/permissionService";

const PrivateRoute = ({ children, path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validRoute, setValidRoute] = useState(false);

  const _userCookies = JSON.parse(Cookies.get("user") || null);
  const user = JSON.parse(localStorage.getItem("user") || null);
  const accessToken = Cookies.get("token");
  const refreshToken = JSON.parse(localStorage.getItem("refresh"));
  const permissions = user?.permissions || [];

  const _verifyTokenAndPermissions = async () => {
    try {
      if (!accessToken) throw new Error("No access token found");

      const isVerified = await verifyToken(accessToken);
      setTokenValid(isVerified);

      const hasPermission = checkRoutePermissions(path, permissions);
      setValidRoute(hasPermission);

      setLoading(false);
    } catch (error) {
      try {
        if (!_userCookies) throw new Error("Sorry, your token has expired.");
        const authenticator = localStorage.getItem("authenticator");
        const isRefreshed = await refreshAccessToken(
          refreshToken,
          authenticator
        );
        setTokenValid(isRefreshed);
        setLoading(false);
      } catch (refreshError) {
        handleAuthError(refreshError, navigate);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    _verifyTokenAndPermissions();
  }, []);

  if (loading) {
    return <ProcessingLoader />;
  }

  if (validRoute && isTokenValid) {
    if (path.includes("/:")) {
      const _path = location.pathname;
      localStorage.setItem("childRoute", _path);
    } else {
      localStorage.setItem("childRoute", path);
    }
    return children;
  }

  return <Navigate to={validRoute ? "/" : "/error"} />;
};

export default PrivateRoute;
