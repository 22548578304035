import { useEffect, useState } from "react";

export const useScrollContainerClass = (scrollContainerRef) => {
  const [containerClass, setContainerClass] = useState("");

  const handleScroll = () => {
    const scrollPosition = scrollContainerRef.current.scrollTop;
    setContainerClass(scrollPosition > 0 ? "sticky-container" : "");
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef]);

  return containerClass;
};
