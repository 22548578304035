import { Button } from '@mui/material';
import React from 'react';
import "./style.scss";

const IconButton = ({
    icon,
    label,
    type
}) => {
    return (

        <Button
            type="submit"
            className={`scai-button ${type}`}
            variant="contained" startIcon={icon}>
            {label}
        </Button>
    )
}

export default IconButton