import { processNodeAndPermissions } from "../../../helper/com-helper";
import { DPAI } from "./DPAI";
import { PAI } from "./PAI";
import { SNOP } from "./SNOP";
import { SNP } from "./SNP";

const IBP_OBJECT = {
  name: "Integrated Business Planning",
  route: "/snop",
  permissions: [],
  child: {
    "S&OP": SNOP,
    "Product Planning": PAI,
    "Demand Planning": DPAI,
    "Supply Planning": SNP,
  },
};

processNodeAndPermissions(IBP_OBJECT);
export const IBP = IBP_OBJECT;
