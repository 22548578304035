import React from "react";
import "./style.scss";

function RipperContainer({ image }) {
  return (
    <div class="image-container">
      <img src={image} alt="Ripple Background" />
    </div>
  );
}

export default RipperContainer;
