// useSelectedMenu.js (Custom Hook for state management)
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { findRouteDetails } from "../helper/com-helper";
import { BAR_MENU } from "../utils/const";

export const useSelectedMenu = () => {
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState({
        parent: null,
        menu: null,
        subMenu: null,
    });

    useEffect(() => {
        const route = location.pathname;
        const selectedBarMenu = findSelectedBarMenu(route);
        const _selected = findRouteDetails(route, selectedBarMenu);
        setSelectedMenu(_selected);
    }, [location.pathname]);

    const findSelectedBarMenu = (route) => {
        for (let key in BAR_MENU) {
            const item = BAR_MENU[key];
            if (item?.childrenRoutes?.includes(route)) {
                return { ...item, parent: key };
            }
        }
        return {};
    };

    return { selectedMenu, setSelectedMenu };
};
