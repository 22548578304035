// permissionsService.js
import { generateRouteAndPermissions } from "../helper/com-helper";
import { BAR_MENU } from "../utils/const";

export const checkRoutePermissions = (path, userPermissions) => {
  const menuPathWithPermissions = generateRouteAndPermissions(
    Object.values(BAR_MENU)
  );
  const route = menuPathWithPermissions[path];
  if (route && route.permissions.some((r) => userPermissions.includes(r))) {
    return true;
  }
  return false;
};
