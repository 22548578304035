export const SNOP = {
  name: "S&OP",
  route: "/snop",
  permissions: ["SNOP_VIEW"],
  extraRoutes: [
    {
      route: "/snop-detail",
      permissions: ["SNOP_VIEW"],
    },
  ],
};
