import { createBrowserHistory } from "history";
import React from "react";
import MicroFrontend from "../../../MicroFrontend";
import SCAILayout from "../../../components/SCAILayout";
import "../../style.scss";
const defaultHistory = createBrowserHistory();

const DTFrontend = () => {
  const { REACT_APP_DT_HOST: dtHost } = process.env;

  function DTModule({ history }) {
    return <MicroFrontend history={history} host={dtHost} name="dt" />;
  }

  return (
    <SCAILayout>
      <div id="dt">
        <div>
          <DTModule history={defaultHistory} />
        </div>
      </div>
    </SCAILayout>
  );
};

export default DTFrontend;
