import { RISKAI } from "./RouteHelper/DTRM";
import { EDM } from "./RouteHelper/EDM";
import { IBP } from "./RouteHelper/IBP";
import { PAI } from "./RouteHelper/IBP/PAI";
import { SNOP } from "./RouteHelper/IBP/SNOP";
import { ID } from "./RouteHelper/InternalDashboard";

export const BAR_MENU = { IBP, EDM, ID, RISKAI };
export const activateSNOPMessage = "Activate the SNOP to access";
export const URLSAFECHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
export const MIN_LENGTH = 58;
export const USER_KEYS = [
  "access",
  "email",
  "first_login",
  "first_name",
  "last_name",
  "logo",
  "mobile",
  "refresh",
  "user_id",
  "username",
];

export const APPBAR_MENU = {
  IBP: "Integrated Business Planning",
  EDM: "Enterprise Data Management",
  DTRM: "Risk Management & Digital Twin",
  ID: "Internal Dashboard",
  CX: "CarbonX",
};

export const APP_MENU = {
  "Integrated Business Planning": "IBP",
  "Enterprise Data Management": "EDM",
  "Risk Management & Digital Twin": "RISKAI",
  "Internal Dashboard": "ID",
};

export const STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
};

export const ENABLE_FOR_MENU = [SNOP.name, PAI.name];
