import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useState } from 'react';
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import ProfileDropDown from "../../assets/svg-icons/profileDropDown.svg";
import {
  business_units,
  businessUnitName,
  firstName,
  lastName,
  selected_business_unit,
} from "../../config/localStorage";
import {
  processNodeAndPermissions,
  redirectToPath,
} from "../../helper/com-helper";
import {
  createBuOptions,
  getPath,
  isAdminUser,
  setUserCookies,
  setUserLocalStorage,
  truncateString,
} from "../../helper/commonHelper";
import colorVariables from "../../style/sass/_colors.scss";
import { getActiveSnop, getConfiguration } from "../../utils/ApiHandler";
import { ROUTES_PATH } from "../../utils/EndPoints";
import { SNOP } from "../../utils/RouteHelper/IBP/SNOP";
import { APPBAR_MENU } from "../../utils/const";
import ProcessingLoader from "../ProcessingLoader";

const SCAIPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    backgroundColor: colorVariables.greyColor1,
    color: colorVariables.greyColor3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: "1rem",
    borderBottomLeftRadius: "1rem",
  },
}));

const MultiAvatar = ({ selectedMenu }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const buOption = createBuOptions(business_units);
  const isAdmin = isAdminUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleBuOption = async (option) => {
    let value = option.value;
    if (selected_business_unit?.business_unit_id !== value) {
      setIsLoading(true);
      setAnchorEl(null);
      const _selected_business_unit = {
        business_unit_id: option.value,
        business_unit_name: option.label,
      };
      localStorage.setItem(
        "selected_business_unit",
        JSON.stringify(_selected_business_unit)
      );
      let user = JSON.parse(Cookies.get("user") || null);
      let user_metadata = JSON.parse(localStorage.getItem("user") || null);
      user = { ...user, ...user_metadata };
      if (!user) return;
      const newUser = setUserLocalStorage(user, value);
      const userPermissions = newUser?.permissions;
      const roles = newUser?.roles;
      if (!userPermissions || !roles) return;
      const path = getPath(userPermissions, roles);
      if (user.tenant_id && _selected_business_unit?.business_unit_id) {
        const configurationResult = await getConfiguration(
          user.access,
          user.tenant_id,
          _selected_business_unit?.business_unit_id
        );
        const { permissions } = processNodeAndPermissions(SNOP);
        let isSandOpPermission = userPermissions.includes(permissions[0]);
        if (!isSandOpPermission) {
          redirectToPath(path);
          return;
        }
        if (configurationResult?.status === 200) {
          await getActiveSnop(
            user.access,
            user.tenant_id,
            _selected_business_unit?.business_unit_id
          );
          setUserCookies(newUser);
          redirectToPath(path);
        }
      } else {
        setUserCookies(newUser);
        redirectToPath(path);
      }
      setIsLoading(false);
    }
  };

  const handleClickMyProfile = () => {
    if (isAdmin) return true;
    navigate(ROUTES_PATH.MY_PROFILE);
  };

  return (
    <>
      {isLoading && <ProcessingLoader />}
      <Stack
        flexDirection="row"
        justifyContent="center"
        alignItems={
          selectedMenu?.parent === APPBAR_MENU.ID ? "center" : "flex-start"
        }
        gap={"0.7rem"}
        onClick={(e) => {
          if (!isAdmin) handleClick(e);
        }}
        sx={{
          cursor: isAdmin ? "auto" : "pointer",
        }}
      >
        <Avatar
          name={`${firstName} ${lastName}`}
          size="32"
          textSizeRatio={1}
          textMarginRatio={0.25}
          round
        // className="avatar"
        />
        <Stack className="header-logout-user" direction={"column"}>
          <Box
            component={"span"}
            className="header-user-name"
          >{`${truncateString(firstName + " " + lastName, 20)}`}</Box>
          <Box component={"span"} className="bu-name">{!isAdmin && `${businessUnitName}`}</Box>
        </Stack>
        {!isAdmin && <img src={ProfileDropDown} alt="ProfileDropDown" />}
      </Stack>
      <SCAIPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          padding: "1rem",
          marginTop: "0.7rem",
        }}
        className='profile-dropdown-container'
      >
        <Box
          sx={{
            maxHeight: 150,
          }}
        >
          <List
            disablePadding
            sx={{
              minWidth: 140,
            }}
          >
            <ListItem disablePadding onClick={handleClickMyProfile}>
              <ListItemButton sx={{
                justifyContent: "space-between",
              }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                >
                  My Profile
                </Typography>
                <ManageAccountsOutlinedIcon
                  sx={{
                    scale: '1.2'
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider variant="middle" />
          </List>
          <Stack>
            <List sx={{
              pt: "0.2rem",
              pb: "1rem"

            }}>
              <ListItem
                disablePadding
                sx={{
                  justifyContent: "space-between",
                  pr: "1.5rem",
                  pl: "0.5rem"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    px: "1.6rem",
                    py: "0.8rem",
                  }}
                >
                  BU's List
                </Typography>
                <ArrowDropDownIcon sx={{
                  scale: "1.3"
                }} />
              </ListItem>

              {buOption.map((option) => {
                return (
                  <>
                    <ListItem
                      disablePadding
                      key={option.value}
                      onClick={() => handleBuOption(option)}
                    >
                      <ListItemButton sx={{
                        justifyContent: "space-between",
                        pl: 4,
                        py: "0.5rem",
                      }}>
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "500",
                            color: selected_business_unit?.business_unit_id ===
                              option.value ? colorVariables.whiteColor : colorVariables.greyColor3,
                          }}
                        >
                          {option.label}
                        </Typography>
                        {selected_business_unit?.business_unit_id ===
                          option.value && (
                            <CheckOutlinedIcon
                              sx={{
                                color: selected_business_unit?.business_unit_id ===
                                  option.value ? colorVariables.whiteColor : colorVariables.greyColor3,
                                scale: "1.1"
                              }}
                            />
                          )}
                      </ListItemButton>
                    </ListItem>
                    {/* <Divider /> */}
                  </>
                );
              })}
            </List>
          </Stack>
        </Box>
      </SCAIPopover>
    </>
  );
}

export default MultiAvatar;
