import { ROUTES_PATH } from "../utils/EndPoints";
import { getLocalStorageItem } from "./localStorageHelper";

const permissions = getLocalStorageItem("user")?.permissions ?? [];
const roles = getLocalStorageItem("user")?.roles ?? [];

export const findRouteDetails = (route, structure) => {
  for (const [key, value] of Object.entries(structure.child || {})) {
    if (value.route === route) {
      return { parent: structure.name, menu: key, subMenu: null };
    }
    if (value.child) {
      const result = findRouteDetails(route, value);
      if (result) {
        return { parent: structure.name, menu: key, subMenu: result.menu };
      }
    }
  }
  if (structure.childrenRoutes && structure.childrenRoutes.includes(route)) {
    return { parent: structure.name, menu: null, subMenu: null };
  }
  return null;
};

export const accessibleMenus = (menu_items) => {
  return menu_items?.filter(
    (menu) =>
      menu?.permissions?.some((ele) => permissions?.includes(ele)) &&
      (!menu?.roles?.length || roles?.includes(menu.roles[0]))
  );
};

export const processNodeAndPermissions = (node) => {
  if (!node) return { routes: [], permissions: [] };
  const routes = node.route ? [node.route] : [];
  let permissions = [...(node.permissions || [])];
  if (node.child) {
    for (const child of Object.values(node.child)) {
      const { routes: childRoutes, permissions: childPermissions } =
        processNodeAndPermissions(child);
      routes.push(...childRoutes);
      permissions = [...new Set([...permissions, ...childPermissions])];
    }
  }

  if (node.extraRoutes) {
    for (const route of node.extraRoutes) {
      if (route.route) {
        routes.push(route.route);
      }
      permissions = [
        ...new Set([...permissions, ...(route.permissions || [])]),
      ];
    }
  }
  if (node.child) {
    node.childrenRoutes = routes;
  }
  node.permissions = [...new Set([...node.permissions, ...permissions])];
  return { routes, permissions };
};

export const generateRouteAndPermissions = (arr) => {
  const master = {};
  const addRoute = (route, permissions) => {
    if (route && permissions.length > 0) {
      master[route] = { route, permissions };
    }
  };

  const traverse = (obj) => {
    if (!obj?.childrenRoutes) addRoute(obj.route, obj.permissions);
    if (obj?.extraRoutes) {
      obj?.extraRoutes?.forEach(({ route, permissions }) => {
        addRoute(route, permissions);
      });
    }
    if (obj.child) {
      Object.values(obj.child).forEach(traverse);
    }
  };

  arr?.forEach((node) => {
    traverse(node);
    node?.extraRoutes?.forEach(({ route, permissions }) => {
      addRoute(route, permissions);
    });
  });

  return master;
};

export const redirectToPath = (menu) => {
  const _path = `${ROUTES_PATH.SCAI}${menu?.route}`;
  window.history.pushState({}, "", _path)
  window.location.replace(_path);
};
