import { processNodeAndPermissions } from "../../../helper/com-helper";
import { DT } from "./DT";

const RISKAI_OBJECT = {
  name: "Risk Management & Digital Twin",
  route: "/riskai/reports",
  permissions: [],
  child: {
    "Risk AI": {
      name: "Risk AI",
      route: null,
      permissions: [],
      child: {
        "Command Center": {
          name: "Command Center",
          route: "/riskai/reports",
          permissions: ["RISK_DASHBOARD_VIEW"],
        },
        "Resilience Monitoring": {
          name: "Resilience Monitoring",
          route: "/riskai/resilienceoverall",
          permissions: ["RISK_COMMAND_CENTRE_VIEW"],
        },
        "Disruption Management": {
          name: "Disruption Management",
          route: "/riskai",
          permissions: ["RISK_COMMAND_CENTRE_VIEW"],
        },
        GAURI: {
          name: "GAURI",
          route: "/riskai/gauri",
          permissions: ["RISK_COMMAND_CENTRE_VIEW"],
        },
        "Digital Twin": DT,
        "Simulation World Engine": {
          name: "Simulation World Engine",
          route: "/riskai/worldengine",
          permissions: ["RISK_COMMAND_CENTRE_VIEW"],
        },
      },
    },
  },
};

processNodeAndPermissions(RISKAI_OBJECT);
export const RISKAI = RISKAI_OBJECT;
