import { processNodeAndPermissions } from "../../../helper/com-helper";
import { DS_DASHBOARD } from "./DS_DASHBOARD";

const ID_OBJECT = {
  name: "Internal Dashboard",
  route: null,
  permissions: [],
  child: {
    Clients: {
      name: "Clients",
      route: "/admin/client",
      permissions: ["CLIENT_VIEW"],
      roles: [],
    },
    Setup: {
      name: "Setup",
      route: "/admin/setup",
      permissions: ["BU_VIEW"],
      roles: ["Client Admin"],
    },
    Configuration: {
      name: "Configuration",
      route: "/admin/configuration",
      permissions: ["CLIENT_VIEWCONFIGURATION"],
      roles: ["Client Admin"],
    },
    Users: {
      name: "Users",
      route: "/admin/user",
      permissions: ["USER_VIEW"],
      roles: [],
    },
    Roles: {
      name: "Roles",
      route: "/admin/role",
      permissions: ["ROLE_VIEW"],
      roles: [],
    },
    "DS Dashboard": DS_DASHBOARD,
  },
  extraRoutes: [
    {
      route: "/admin/client/edit",
      permissions: ["CLIENT_EDIT"],
      roles: [],
    },
    {
      route: "/admin/setup/create",
      permissions: ["BU_CREATE"],
      roles: [],
    },
    {
      route: "/admin/setup/edit",
      permissions: ["BU_EDIT"],
      roles: [],
    },
    {
      route: "/admin/setup/configuration",
      permissions: ["BU_VIEWCONFIGURATION"],
      roles: [],
    },
    {
      route: "/admin/setup/team",
      permissions: ["TEAM_VIEW"],
      roles: [],
    },
    {
      route: "/admin/setup/team/create",
      permissions: ["TEAM_CREATE"],
      roles: [],
    },
    {
      route: "/admin/setup/team/edit",
      permissions: ["TEAM_EDIT"],
      roles: [],
    },
    {
      route: "/admin/setup/team/user",
      permissions: ["USER_VIEW"],
      roles: [],
    },
    {
      route: "/admin/user/create",
      permissions: ["USER_CREATE"],
      roles: [],
    },
    {
      route: "/admin/user/edit",
      permissions: ["USER_EDIT"],
      roles: [],
    },
    {
      route: "/admin/role/edit",
      permissions: ["ROLE_EDIT"],
      roles: [],
    },
  ],
};

processNodeAndPermissions(ID_OBJECT);
export const ID = ID_OBJECT;
