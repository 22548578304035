// authService.js
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { logout, tokenRefresh, tokenVerify } from "../utils/ApiHandler";

export const verifyToken = async (accessToken) => {
  try {
    const res = await tokenVerify(JSON.parse(accessToken));
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const refreshAccessToken = async (refreshToken, authenticator) => {
  try {
    const res = await tokenRefresh(refreshToken, authenticator);
    if (res.status === 200) {
      const _token = JSON.stringify(res.data.access);
      Cookies.set("token", _token, { expires: 1 });
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const handleAuthError = (error, navigate) => {
  const errorMessage =
    error?.response?.data?.detail || error?.message || "Authentication failed";
  toast.error(errorMessage);
  logout();
  navigate("/");
};
