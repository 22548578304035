import { createBrowserHistory } from "history";
import React from "react";
import MicroFrontend from "../../../MicroFrontend";
import SCAILayout from "../../../components/SCAILayout";
import "../../style.scss";
const defaultHistory = createBrowserHistory();

const RMFrontend = () => {
    const { REACT_APP_RM_HOST: rmHost } = process.env;

    function RMModule({ history }) {
        return <MicroFrontend history={history} host={rmHost} name="riskai" />;
    }

    return (
        <SCAILayout>
            <div id="riskai">
                <div>
                    <RMModule history={defaultHistory} />
                </div>
            </div>
        </SCAILayout>
    );
};

export default RMFrontend;
