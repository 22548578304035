import { Stack, styled, TextField } from "@mui/material";
import React from "react";
import CustomFieldValidator from "../../components/CustomFieldValidator";
import colorVariables from "../../style/sass/_colors.scss";
import "./input-field.scss";

const ScaiTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    borderRadius: '0.8rem',
    backgroundColor: `${colorVariables.disabledColor}!important`
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '1.2rem',
    color: colorVariables.greyColor3,
    borderRadius: '0.8rem',
    '& fieldset': {
      borderColor: colorVariables.borderColor, // Initial border color
    },
    '&:hover fieldset': {
      borderColor: colorVariables.borderColor, // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: colorVariables.borderColor, // No border color change on focus
    },
  },
  '& .MuiInputBase-input': {
    color: `${colorVariables.greyColor3}!important`,
    fontSize: '1.2rem', // Font size for input data
    height: "2.6rem",
    padding: "0.65rem 1.3rem"
  },
  '& .Mui-disabled': {
    opacity: 1,
    '-webkit-text-fill-color': `${colorVariables.disabledText}!important`, // Style for disabled input text color
  },
  '& .Mui-disabled:before': {
    border: "none !important"
  },
  '& .MuiInputBase-input::placeholder': {
    color: colorVariables.greyColor3,
    fontSize: '1.2rem', // Font size for placeholder
  },
});

const InputField = ({
  label,
  name,
  disabled,
  onChange,
  value,
  validator,
  validations
}) => {

  return (
    <Stack direction={"column"}>
      <ScaiTextField
        id="outlined-basic"
        placeholder={label}
        name={name}
        variant={disabled ? "filled" : "outlined"}
        onChange={onChange}
        value={value}
        className={`scai-input-field ${disabled && "disabled"}`}
        disabled={disabled}
      />
      <CustomFieldValidator
        validator={validator}
        field={name}
        inputValue={value}
        validations={validations}
      />
    </Stack>
  );
};

export default InputField;
