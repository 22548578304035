// CustomHooks/useTabNavigation.js
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { accessibleMenus, redirectToPath } from "../helper/com-helper";
import { getLocalStorageItem } from "../helper/localStorageHelper";
import { activateSNOPMessage, APPBAR_MENU, ENABLE_FOR_MENU } from "../utils/const";

export const useTabNavigation = ({
  content,
  classname,
  isSuperMenu,
  clickedMenu,
  setShowSubMenu,
  setSelectedMenu,
  selectedMenu,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [animate, setAnimate] = useState(false);
  const isSubMenu = classname && !isSuperMenu;
  const isScrollable = isSubMenu && content?.length > 5;
  const [activeTab, setActiveTab] = useState(0);
  const [subMenu, setSubMenu] = useState([]);
  const snop = getLocalStorageItem("snop") ?? {};
  const isEmptySNOP = isEmpty(snop);

  useEffect(() => {
    if (content?.length > 0) {
      const defaultActiveTab = content.findIndex((item) =>
        item?.childrenRoutes
          ? item?.childrenRoutes?.includes(location?.pathname)
          : item?.route === location?.pathname
      );
      // if (defaultActiveTab !== activeTab) {
      setActiveTab(defaultActiveTab > -1 ? defaultActiveTab : null);
      // }
      if (isSubMenu) {
        const allItemsHaveNoChildren = content.every((item) => !item?.child);
        if (allItemsHaveNoChildren) handleMenuClose();
      }

      if (
        !classname &&
        defaultActiveTab > -1 &&
        content[defaultActiveTab]?.child
      ) {
        setShowSubMenu(true);
      }
    }
  }, [content, clickedMenu]);

  const handleMenuClose = () => {
    setExpanded(false);
    setTimeout(() => {
      setSubMenu([]);
      setAnimate(false);
    }, 300);
  };

  const handleChange = (event, newValue) => {
    const menu = content[newValue];
    if (isTabDisabled(menu)) {
      toast.error(activateSNOPMessage, { toastId: newValue });
      return;
    }
    handleSubMenuLogic(menu, newValue);
    updateSelectedMenu(menu);
    if (menu?.route) redirectToPath(menu);
  };

  const isTabDisabled = (menu) => {
    return (
      !ENABLE_FOR_MENU.includes(menu?.name) &&
      isEmptySNOP &&
      !classname &&
      selectedMenu?.parent === APPBAR_MENU?.IBP
    );
  };

  const handleSubMenuLogic = (menu, newValue) => {
    if (!isSubMenu || (isSubMenu && !menu?.child)) {
      handleMenuClose();
    }

    if (isSubMenu && menu?.child) {
      handleMenuExpansion(menu, newValue);
    }
    setShowSubMenu?.(!!menu?.child && !classname);
  };

  const updateSelectedMenu = (menu) => {
    const selected = isSuperMenu
      ? { parent: menu?.name, menu: null, subMenu: null }
      : isSubMenu
        ? { ...selectedMenu, subMenu: menu?.name }
        : { ...selectedMenu, menu: menu?.name, subMenu: null };

    setSelectedMenu(selected);
  };

  const handleMenuExpansion = (menu, idx) => {
    const childs = accessibleMenus(Object.values(menu?.child));
    setSubMenu(childs);
    setAnimate(true);
    setTimeout(() => setExpanded(true), 350);
  };

  const handleDropDownMenuClick = (menu) => {
    redirectToPath(menu);
  };

  return {
    activeTab,
    expanded,
    animate,
    isSubMenu,
    isScrollable,
    isEmptySNOP,
    subMenu,
    handleChange,
    handleMenuClose,
    handleMenuExpansion,
    handleDropDownMenuClick,
  };
};
