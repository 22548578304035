import { Box, Stack } from "@mui/material";
import React from "react";
import Select, { components } from "react-select";
import { createThemeSelect } from "../../constant/constant";
import images from "../../constant/images";
import CustomFieldValidator from "../CustomFieldValidator";

function SelectField({
  validator,
  validations,
  value,
  name,
  label,
  placeholder,
  options,
  onChange,
}) {

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Box
          component="img"
          src={images.dropDownIcon}
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <Stack direction={"column"}>
      <Select
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        theme={createThemeSelect}
        classNamePrefix="mySelect"
        name={name}
        placeholder={placeholder}
        label={label}
        options={options}
        value={value}
        onChange={onChange}
      />
      <CustomFieldValidator
        validator={validator}
        field={name}
        inputValue={value}
        validations={validations}
      />
    </Stack>
  );
}

export default SelectField;
