import { Box, Grid, Stack, Typography } from "@mui/material";
import { } from "i18next";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import IconButton from "../../components/button";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import InputField from "../../components/InputField";
import ProcessingLoader from "../../components/ProcessingLoader";
import SelectField from "../../components/SelectField";
import { tenantId, user, userId } from "../../config/localStorage";
import { nameValidation, userEditInitialValues } from "../../constant/constant";
import {
  createPayloadUserEdit,
  createUserData,
  updatedCities,
  updatedCountries,
  updatedStates,
} from "../../helper/commonHelper";
import { editUserAPI, getUserAPI } from "../../utils/ApiHandler";
import { STATUS_CODE } from "../../utils/const";
import { COM_MSG, ERROR_MSG } from "../../utils/messages";
import "./styles.scss";
const validator = new SimpleReactValidator();

function MyProfile() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setForceUpdate] = useState(false);
  const [formData, setFormData] = useState(userEditInitialValues);

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await getUserAPI({
        orgId: tenantId,
        userId: userId,
      });
      if (STATUS_CODE.OK !== status) throw new Error(ERROR_MSG.SWR);
      const newFormData = createUserData({ data });
      setFormData(newFormData);
    } catch (err) {
      toast.error(err?.messages || ERROR_MSG.SWR);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!tenantId || !userId) return;
    fetchUserData();
  }, [tenantId, userId]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    validator.showMessages();
    setForceUpdate((prev) => !prev);
    if (!validator.allValid()) return;
    try {
      setIsLoading(true);
      const payload = createPayloadUserEdit(formData, userId);
      const { status, data } = await editUserAPI({
        payload,
        orgId: tenantId,
        userId: userId,
      });
      if (STATUS_CODE.OK !== status) throw new Error(ERROR_MSG.SWR);
      const newUserData = {
        ...user,
        first_name: data.first_name,
        last_name: data.last_name,
      };
      Cookies.set("user", JSON.stringify(newUserData), { expires: 1 });
      toast.success(COM_MSG.USER_UPDATED);
      window.location.reload();
    } catch (err) {
      toast.error(err?.messages || ERROR_MSG.SWR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <ProcessingLoader />}
      <Box className="scai-profile-container">
        <Typography className="scai-section-heading">
          {t("my_profile")}
        </Typography>

        <Box
          component="form"
          sx={{ my: 1 }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          className="scai-profile-inputs"
        >
          <Typography className="scai-sub-heading" sx={{
            marginY: "1.5rem"
          }}>
            {"User Information"}
          </Typography>
          <Grid container spacing={3} className="scai-grid-container">
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"First Name*"}
                name={"first_name"}
                value={formData.first_name}
                onChange={handleOnChange}
                validator={validator}
                validations={nameValidation}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Last Name*"}
                name={"last_name"}
                value={formData.last_name}
                onChange={handleOnChange}
                validator={validator}
                validations={nameValidation}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Email ID*"}
                disabled
                name={"email"}
                value={formData.email}
                validator={validator}
                validations={'required|email'}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CustomPhoneInput
                placeholder="Contact Number**"
                className="scai-phone-input"
                name={"Mobile"}
                value={formData.mobile}
                onChange={(val) => setFormData({ ...formData, mobile: val })}
                validator={validator}
                validations={["required", "phone", { max: "13" }]}
              />
            </Grid>
            <Grid item xs={12} md={2.4} />
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Client"}
                name={"organization"}
                disabled
                value={formData.organization}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Business Unit"}
                disabled
                name={"business_unit"}
                value={formData.business_unit}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Team"}
                disabled
                name={"team"}
                value={formData.team}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Roles"}
                disabled
                name={"roles"}
                value={formData.roles}
              />
            </Grid>
            <Grid item xs={12} md={2.4} />
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Address Line 1*"}
                name={"address_line1"}
                value={formData.address_line1}
                onChange={handleOnChange}
                validator={validator}
                validations={["required", { max: "400" }]}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Address Line 2*"}
                name={"address_line2"}
                value={formData.address_line2}
                onChange={handleOnChange}
                validator={validator}
                validations={["required", { max: "400" }]}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <SelectField
                id="country"
                name="country"
                placeholder="Country*"
                label="Country*"
                options={updatedCountries}
                value={formData.country}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    country: value,
                    state: null,
                    city: null,
                  });
                }}
                validator={validator}
                validations={["required"]}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <SelectField
                id="state"
                name="state"
                placeholder="State*"
                label="State*"
                isDisabled={!formData.country}
                value={formData.state}
                options={formData.country ? updatedStates(formData.country.value) : []}
                onChange={(value) => {
                  setFormData({ ...formData, state: value, city: null });
                }}
                validator={validator}
                validations={["required"]}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <SelectField
                id="city"
                name="city"
                placeholder="City"
                label="City"
                isDisabled={!formData.state}
                value={formData.city}
                options={
                  formData.state
                    ? updatedCities(formData.country.value, formData.state.value)
                    : []
                }
                onChange={(value) => {
                  setFormData({ ...formData, city: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputField
                label={"Pincode*"}
                name={"pincode"}
                value={formData.pincode}
                onChange={handleOnChange}
                validator={validator}
                validations={"required|numeric|min:4|max:16"}
              />
            </Grid>
          </Grid>

          <Stack direction={"row-reverse"} gap={"1.5rem"}>
            <IconButton label={t("update")} type={"update"} />
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default MyProfile;
