export const DT = {
    name: "Digital Twin",
    route: "/dt",
    permissions: ["RISK_DIGITAL_TWIN_VIEW"],
    extraRoutes: [
        {
            route: "/dt/nodestats",
            permissions: ["RISK_DIGITAL_TWIN_VIEW"],
        },
        {
            route: "/dt/shipmentstats",
            permissions: ["RISK_DIGITAL_TWIN_VIEW"],
        },
    ]
}