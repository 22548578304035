import { City, Country, State } from "country-state-city";
import Cookies from "js-cookie";
import { get, isEmpty } from "lodash";
import {
  convertLocalNumber,
  getPhoneCountryCode,
} from "../components/CustomPhoneInput";
import { buId, tenantId } from "../config/localStorage";
import { adminType } from "../constant/constant";
import { getActiveSnop, getConfiguration } from "../utils/ApiHandler";
import { BAR_MENU, MIN_LENGTH, URLSAFECHARS, USER_KEYS } from "../utils/const";
import { redirectToPath } from "./com-helper";
import { getLocalStorageItem } from "./localStorageHelper";

// Helper function to check permissions and roles
const hasPermissionsAndRoles = (
  nodePermissions,
  nodeRoles,
  permissions,
  roles
) => {
  const hasPermission = nodePermissions.some((permission) =>
    permissions.includes(permission)
  );
  const hasRole = nodeRoles
    ? nodeRoles.length === 0 || nodeRoles.some((role) => roles.includes(role))
    : true;
  return hasPermission && hasRole;
};

// Recursive function to traverse child nodes
const traverseChildren = (children, permissions, roles, matchedNodes) => {
  for (const key in children) {
    const child = children[key];

    if (
      child?.permissions &&
      child?.route &&
      hasPermissionsAndRoles(
        child.permissions,
        child.roles || [],
        permissions,
        roles
      )
    ) {
      matchedNodes.push(child); // Add matched child node
    }

    if (child.child) {
      traverseChildren(child.child, permissions, roles, matchedNodes);
    }
  }
};

// Function to check extra routes
const checkExtraRoutes = (extraRoutes, permissions, roles, matchedNodes) => {
  extraRoutes?.forEach((item) => {
    if (
      hasPermissionsAndRoles(
        item.permissions,
        item.roles || [],
        permissions,
        roles
      ) &&
      item?.route
    ) {
      matchedNodes.push(item);
    }
  });
};

export const getPath = (permissions, roles) => {
  const inputArray = Object.values(BAR_MENU);
  const matchedNodes = [];
  inputArray.forEach((idObject) => {
    if (idObject.child) {
      traverseChildren(idObject.child, permissions, roles, matchedNodes);
    }
    checkExtraRoutes(idObject.extraRoutes, permissions, roles, matchedNodes);
  });
  return matchedNodes.length > 0 ? matchedNodes[0] : null;
};

export const getUserMetaData = (_user) => {
  const metaData = {},
    user = {};
  for (let key in _user) {
    if (USER_KEYS.includes(key)) user[key] = _user[key];
    else metaData[key] = _user[key];
  }
  return { metaData, user };
};

export const setUserCookies = (_user) => {
  const { metaData, user } = getUserMetaData(_user);
  Cookies.set("user", JSON.stringify(user), { expires: 1 });
  localStorage.setItem("user", JSON.stringify(metaData));
};

export const processLogin = async (res, setLoader, selected_business_unit) => {
  if (res?.status === 200) {
    const users = res.data.data;
    const user = setUserLocalStorage(users);
    const path = !isEmpty(res) && getPath(user.permissions, user.roles);

    if (user.tenant_id && selected_business_unit?.business_unit_id) {
      const configurationResult = await getConfiguration(
        user.access,
        user.tenant_id,
        selected_business_unit?.business_unit_id
      );
      if (configurationResult?.status === 200) {
        await getActiveSnop(
          user.access,
          user.tenant_id,
          selected_business_unit?.business_unit_id
        );
        setUserCookies(user);
        redirectToPath(path);
      }
    } else {
      setUserCookies(user);
      redirectToPath(path);
    }
  }
  setLoader(false);
};

export const generateUrlSafeString = () => {
  let result = "";
  const charsLength = URLSAFECHARS.length;
  for (let i = 0; i < MIN_LENGTH; i++) {
    const array = new Uint32Array(1);
    result += URLSAFECHARS.charAt(
      Math.floor(crypto.getRandomValues(array) % charsLength)
    );
  }
  return result;
};

export function arrayBufferToBase64Url(buffer) {
  const byteArray = new Uint8Array(buffer);
  const base64 = btoa(String.fromCharCode.apply(null, byteArray));
  return base64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/={0,100}$/, "");
}

// Function to compute SHA-256 hash and encode it as Base64URL
export async function sha256Base64UrlEncoded(data) {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);

  const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);
  const base64UrlEncoded = arrayBufferToBase64Url(hashBuffer);

  return base64UrlEncoded;
}

export const isNotificationEnabled = () => {
  if (buId && tenantId) return true;
  return false;
};

export const createBuOptions = (business_units) => {
  return business_units.map((bu) => {
    return {
      value: bu.id,
      label: bu.name,
      is_default: bu.is_default,
      teamId: bu?.team?.id,
    };
  });
};

export const isAdminUser = () => {
  const user = getLocalStorageItem("user");
  const roles = get(user, "roles", []);
  for (let i = 0; i < roles.length; i++) {
    let role = roles[i];
    if (role in adminType) return true;
  }
  return false;
};

//Country state ciy dropdown Options
export const updatedStates = (countryId) =>
  State.getStatesOfCountry(countryId).map((state) => ({
    label: state.name,
    value: state.isoCode,
    ...state,
  }));

export const updatedCities = (countryId, stateId) =>
  City.getCitiesOfState(countryId, stateId).map((city) => ({
    label: city.name,
    value: city.isoCode,
    ...city,
  }));

export const updatedCountries = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: country.isoCode,
  ...country,
}));

export const findCountry = (countryName) => {
  return Country.getAllCountries().find(
    (country) => country.name === countryName
  );
};

export const findState = (countryId, stateName) => {
  const states = State.getStatesOfCountry(countryId);
  return states.find((state) => state.name === stateName);
};

export const createPayloadUserEdit = (formData, userId) => {
  const data = formData?.data || {};
  let localNumber = convertLocalNumber(formData.mobile);
  let countryCode = getPhoneCountryCode(formData.mobile);
  const bu = getLocalStorageItem("selected_business_unit");
  const buId = bu?.business_unit_id;
  const businessUnits = data?.business_units?.map((item) => {
    const bu = {
      business_unit: item?.id,
      is_default: item?.id === buId ? true : false,
      team: item?.team?.id,
      roles: item?.roles.map((rl) => rl?.role?.id),
    };
    return bu;
  });

  const payload = {
    id: userId,
    organization: data?.organization?.id,
    business_unit: data?.business_unit?.id,
    team: data?.team?.id,
    first_name: formData.first_name,
    last_name: formData.last_name,
    email: formData.email,
    mobile: localNumber,
    mobile_country_code: countryCode,
    address_line1: formData.address_line1,
    address_line2: formData.address_line2,
    country: formData.country?.label,
    state: formData.state?.value !== "" ? formData.state?.label : "",
    city: formData.city?.value !== "" ? formData.city?.label : "",
    pincode: formData.pincode,
    // roles: data?.roles?.map((role) => role.id) || [],
    business_units: businessUnits,
  };
  return payload;
};

export const createUserData = ({ data }) => {
  const country = findCountry(data?.country);
  const state = findState(country?.isoCode, data?.state);
  let roles = [];
  let buName = "";
  let teamName = "";
  const bu = getLocalStorageItem("selected_business_unit");
  const buId = bu?.business_unit_id;
  const businessUnits = data?.business_units;
  const businessUnitsLn = businessUnits?.length;
  for (let i = 0; i < businessUnitsLn; i++) {
    const buItem = businessUnits[i];
    if (buItem?.id !== buId) continue;
    buName = buItem?.name;
    teamName = buItem?.team?.name;
    const rl = buItem?.roles;
    const rlLn = rl?.length;
    for (let i = 0; i < rlLn; i++) {
      const rlItem = rl[i];
      const name = rlItem?.role?.name;
      roles.push(name);
    }
  }
  let obj = {
    organization: data?.organization?.name || "",
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    mobile_country_code: data?.mobile_country_code || "",
    mobile: data?.mobile_country_code + data?.mobile || "",
    team: teamName || "",
    business_unit: buName || "",
    address_line1: data?.address_line1 || "",
    address_line2: data?.address_line2 || "",
    country: {
      label: country?.name || data?.country || "Country",
      value: country?.isoCode || data?.country || "",
    },
    state: {
      label: state?.name || data?.state || "State",
      value: state?.isoCode || data?.state || "",
    },
    city: {
      label: data?.city || "City",
      value: data?.city || "",
    },
    pincode: data?.pincode || "",
    roles: roles?.join(", ") || "",
    data,
  };
  return obj;
};

export const userObj = (user, id) => {
  let permissions = [];
  let roles = [];
  let businessUnit = user?.business_units;
  let businessUnitLn = businessUnit?.length;
  if (businessUnitLn === 0) return user;
  for (let i = 0; i < businessUnitLn; i++) {
    let buItem = businessUnit[i];
    if (id && buItem?.id !== id) continue;
    if (!id && !buItem?.is_default) continue;
    roles = buItem?.roles;
    permissions = buItem?.permissions;
  }
  let newObj = {
    ...user,
    permissions,
    roles,
  };
  return newObj;
};

export const setUserLocalStorage = (user, id) => {
  let isAdmin = false;
  let roles = user?.roles;
  const rolesLn = roles?.length;
  for (let i = 0; i < rolesLn; i++) {
    let role = roles[i];
    if (role in adminType) {
      isAdmin = true;
      break;
    }
  }
  const newUser = userObj(user, id);
  if (id) {
    return newUser;
  } else {
    let userObj = isAdmin ? user : newUser;
    return userObj;
  }
};

export const findDefaultBu = (buArr) => {
  const bu = buArr.find((bu) => bu.is_default);
  return {
    business_unit_id: bu?.id,
    business_unit_name: bu?.name,
  };
};

export function truncateString(input, num) {
  if (input.length > num) {
    return input.substring(0, num) + "...";
  }
  return input;
}
