import { createBrowserHistory } from "history";
import Cookies from 'js-cookie';
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SCAILayout from "../../../components/SCAILayout";
import MicroFrontend from "../../../MicroFrontend";
import "../../style.scss";
const defaultHistory = createBrowserHistory();

const SnopFrontend = () => {
  const { REACT_APP_SNOP_HOST: snopHost } = process.env;
  const user = JSON.parse(Cookies.get("user") || null);
  let navigate = useNavigate();

  function SnopModule({ history }) {
    return <MicroFrontend history={history} host={snopHost} name="snop" />;
  }

  useEffect(() => {
    if (isEmpty(user)) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <SCAILayout>
      <div id="snop">
        <div>
          <SnopModule history={defaultHistory} />
        </div>
      </div>
    </SCAILayout>
  );
};

export default SnopFrontend;
